$bg-color: #F6F6F6;

$grey: #565656;
$grey-dark: #293840;

$orange: #F39100;
$orange-dark: #F37200;

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	margin: 0;
	font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
	color: $grey;
	background-color: $bg-color;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: $orange-dark;
	font-weight: 500;
	
	&:active,
	&:focus {
		outline: none;
	}
	
	&:visited {
		color: $orange-dark;
	}
}

h1,
h2,
h3 {
    line-height:  1.2;
}

h1 {
	font-weight: 500;
	font-size: 2.5em;
	color: $orange-dark;
	margin-top: 80px;
    margin-bottom: 10px;
}

h2 {
	color: $grey;
	font-weight: 500;
	font-size: 1.825em;
	margin: 0;
	margin-top: 40px;
    margin-bottom: 0px;
}

h3 {
	color: $grey;
	font-weight: 500;
    margin-top: 40px;
    margin-bottom: 0px;
	font-size: 1.625em;
}


h4 {
	color: $grey;
	font-weight: 500;
	font-size: 1.375em;
}

blockquote h3 {
	@extend h2;	
	margin-top: 0px;
}

blockquote,
.subheadline {
	margin: 0;
    text-align: center;
}

.col-md-5 h2 {
    margin-top: 0;
}

cite {
	font-style: normal;
	display: inline-block;
	margin: 20px 0;
}

p {
    font-size: 1.125em;
    line-height:  1.4;
}

ul {
	font-size: 1.125em;
    line-height:  1.4;
	
	li {
		margin-bottom:  5px;
	}

}

img {
	max-width: 100%;
}

.bg-grey {
	background-color: $grey;
}

.bg-grey-dark {
	background-color: $grey-dark;
}

.bg-orange {
	background-color: $orange;
}

.bg-orange-dark {
	background-color: $orange-dark;
}


.text-center {
	text-align: center;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}