@import 'core/_grid';
@import '_basis';

#header {
	background-color: #FFF;

	.logo {
		width: 160px;
		float: left;
		margin-top: 20px;
        margin-bottom: 20px;
	}
	
}

#mobile-menu {
    display: none;
}

#nav_wrapper.open #main_nav {
    display: block;
}

#main_nav {
	// font-size: 1.0625em;
	float: right;
    display:  block;
	text-transform: uppercase;
	
	ul {
		@extend .clearfix;
		list-style: none;
		margin: 55px 0;
		padding: 0px;
	
		> li {
			margin-left: 45px;
			position: relative;
			float: left;

			&:first-child {
				margin-left: 0;
			}
			
			&:hover {
				.sub-menu {
					visibility: visible;
					opacity: 1;
					
					-webkit-transition: opacity .5s;
					-moz-transition: opacity .5s;
					-ms-transition: opacity .5s;
					-o-transition: opacity .5s;
					transition: opacity .5s;
				}
			}
			
			> a {
                display: block;
				padding: 5px 0;
                font-weight: 600;
                letter-spacing: 0.5px;
				line-height: 1.25rem;
				color: $grey;
				
			}
			
			&.aktiv {
				> a {
					color: $orange-dark;
				}
			}
			
			.sub-menu {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				padding: 0;
				margin: 0;
				right: -15px;
				background-color: #FFF;
				min-width: 200px;
				padding: 10px 15px;
				text-align: right;
				
				li {
					float: none;
					margin: 0;
					
					a {
						color: $grey;
                        font-size:  1rem;
                        text-transform:  none;
                        font-weight: 500;
					}
				}
			}
			
		}
	
	}

}

/*
 * Kopfgrafik
 */
 
#kopfgrafik {

	picture {
		margin: 0 auto;
		max-width: 1100px;
		display: block;
	}
	
	.panels.home {
		margin: -75px 0 0 0;
	}

}


/*
 * Pannels 
 */

.panels {
	@extend .clearfix;
	@extend .text-center;
	
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
	
	.panel {
	
		a {
			padding: 40px 20px 80px 20px;
			display: inline-block;
			height: 100%;
			min-height: 100%;
		}
		
		.arrow {
			position: absolute;
			right: 20px;
			bottom: 20px;
		}
	}
	
	p,
	h3, 
	h4 {
		color: #FFF;
	}
    
    p {
        font-size:  1.063em;
		margin: 10px 10px 0px 10px;
	}
	
	h3 {
		font-size:  1.4em;
		line-height: 1;
		margin: 0px 0px 20px 0px;
	}
		
}

/*
 * willkommenstext
 */

.willkommenstext {
	margin-bottom: 80px;
}
 
/*
 * Inhalt
 */
 
#Inhalt {
	// min-height: 300px;
	padding-top: 60px;
	padding-bottom: 60px;
}
 
/*
 * Footer
 */

#footer {
	margin-top: 100px;
	background-color: $grey-dark;
	
	.menu {
		padding: 35px 0;
		
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			float: right;
			
			li {
				float: left;
				margin-left: 25px;
				
				a {
					color: #FFF;
					line-height: 1.875em;
				}
			}
		}
	
	}
	
}

/*
 * Kontakttabelle
 */
table {
    input {
        border: 1px solid rgba(86, 86, 86,0.3);
        padding: 10px;
        margin-bottom: 3px;
        margin-top: 3px;
    }
    
    .edit {
        width: 100%;
    }
    
    .wohnort {
        width: 60%;
    }
    
    .plz {
        width: 39%;
        float: left;
    }
    
    textarea {
        width: 100%;
        min-height: 200px;
        border: 1px solid rgba(86, 86, 86,0.3);
    }
    
    .button {
        margin-top: 20px;
        padding: 10px 15px;
        background: $orange-dark;
        color: #fff;
        border: none;
    }
}


#map_canvas {
    display: block;
    height: 400px;
    width: 100%;
    border: 1px solid rgba(86, 86, 86,0.1);
    
        #Tooltip {
            
            h1 {
                font-size: 20px;
                margin-bottom: 10px;
                margin-top: 10px;
            }
            p {
                margin-bottom: 0px;
                margin-top: 0px;
            }
    }

}

#MODUL_Neuigkeiten {
	#umsNeuigkeit {
		background: #fff;
		border-top: 3px solid $orange-dark;
		padding: 35px 30px 20px 30px;
		margin-bottom: 20px;
		
		h1 {
			font-size: 1.625em;
			margin-top: 0px;
			color: $grey;
		}
		
		#umsNeuigkeitDatum {
			color: $orange-dark;
			font-weight: 600;
		}
	}
	
}